import React, { useEffect } from 'react'
import '../../styles/Testimonials.css'
import Flickity from 'flickity';
import 'flickity/css/flickity.css';


function Testimonials() {
    useEffect(() => {
        const flickityCarousel = new Flickity('.main-carousel', {
            initialIndex: 0,
            cellAlign: 'left',
            contain: true,
            draggable: true,
            wrapAround: true,
            freeScroll: true,
            prevNextButtons: true,
            autoPlay: true,
            selectedAttraction: 0.01,
            friction: 0.20,
            pageDots: true,
            imagesLoaded: true,
            pauseAutoPlayOnHover: false,
        });

        return () => {
            flickityCarousel.destroy();
        };
    }, []);
    return (
        <div className='Testimonials'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Testimonials</h2>
                <div className="main-carousel">

                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>
                                    A variety experience for sure, who would've thought chicken stuffed inside an ice-cream cone could give someone such a wow factor. Not only the presentation.. The food itself was good as well, i personally liked the 'Dynamite Chicken Cone' - Cheese and chicken in the right ratio.. The TexMex and Yella Bbq cone are worth mentioning too.
                                    Keep up the good work guys. Will be back for more♥️</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/prabhu_raj.png" alt="avatar" />

                            <h3>Prabhu Raj</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>Delicious food with best ambience and an amazing service.Must try!❤one of the best spot in kochi.Those who are in kochi don't miss out..</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>

                            <img src="img/testimonials/be_with_murshida.png" alt="avatar" />
                            <h3>Be With Murshida</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>Best place with different varieties of chicken cones that too for affordable prices. It might take some time to get it delivered but definitely worth our time and penny</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/maria.png" alt="avatar" />
                            <h3>Maria Ben</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>We tried the Tex Mex chicken cone and the Cheesy chicken cone. It was delicious. Both are a must try.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/albert.png" alt="avatar" />
                            <h3>Albert Alias</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>The place is very good with parking
                                    The staffs are very friendly
                                    Highly recommended</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/sanju.png" alt="avatar" />
                            <h3>Sanju S</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>One of the best in Kochi, Absolutely loved it. Tex Mex and Chesse is a must try</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/solo_gaming.png" alt="avatar" />
                            <h3>Solo Gaming OMG</h3>

                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Testimonials