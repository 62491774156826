import React from 'react'
import AboutBanner from '../Components/AboutBanner/AboutBanner'
import AboutUs from '../Components/AboutUs/AboutUs'
import Footer from '../Components/Footer/Footer'
import Mission from '../Components/Mission/Mission'

function About() {
  return (
    <div className='About'>
      
        <AboutBanner/>
        <AboutUs/>
        <Mission/>
        <Footer/>
        
    </div>
  )
}

export default About