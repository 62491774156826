import { createContext, useEffect, useState } from "react";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [image, setImage] = useState('img/Chiccone_Logo_circle.png');
    const [overlay, setOverlay] = useState(false);
    const html = document.querySelector('html');

    const preloadImage = (src) => {
        const img = new Image();
        img.src = src;
    };

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
        setOverlay(!overlay);
        html.style.overflowY = html.style.overflowY === 'hidden' ? 'auto' : 'hidden';
    };

    const handleNavbar = () => {
        if (window.scrollY > 600) {
            setImage("img/Chiccone_Logo__.png");
            setNavbar(true);
        } else {
            setImage("img/Chiccone_Logo_circle.png");
            setNavbar(false);
        }
    };

    useEffect(() => {
        preloadImage("img/Chiccone_Logo__.png"); 
        handleNavbar(); 
        window.addEventListener('scroll', handleNavbar);

    },[]); 
    return (
        <MenuContext.Provider value={{ menuOpen, navbar, handleToggleMenu, handleNavbar, image, setImage, overlay }}>
            {children}
        </MenuContext.Provider>
    );
};

export default MenuContext;
