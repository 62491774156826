import React, { useEffect,useContext } from 'react';
import '../../styles/AboutBanner.css'
import Navbar from '../Navbar/Navbar';
import MenuContext from '../../Context/menuProvider'





function AboutBanner() {
    const {image,setImage,navbar} = useContext(MenuContext);



    useEffect(() => {
        if (window.scrollY > 600) {
            setImage("img/Chiccone_Logo__.png");
        } else {
            setImage("img/Chiccone_Logo__white.png");
        }
      });
    return (
        <div className='AboutBanner'>
            <Navbar />
            <div className="container">
                <img src="img/Aboutbanner_bg.png" alt="" />
            </div>
            <div className="gradient">

            </div>
            <div className="text">
                <h2 >About Us</h2>
            </div>

        </div>
    )
}

export default AboutBanner