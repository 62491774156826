import React from 'react'
import '../../styles/Herosection.css'



function Herosection() {
  return (
    <div className='Herosection'>
      <div className="container">
        <div className="content custom-fade-right"data-aos="custom-fade-right" data-aos-duration="1000">
          <h1><span>Get Ready to Rumble :</span> Savor the Deliciousness of Crispy Chicken Cones</h1>
          <p>Forget boring chicken! Chiccone serves up crispy, flavor-packed masterpieces in convenient cones. Dive into our menu and experience a symphony of taste you won't forget.</p>
          {/* <a href="/offers">Check Out</a> */}
        </div>
        <div className="image custom-fade-left"data-aos="custom-fade-left" data-aos-duration="1000">
          <img src="img/herosection-intro.png" alt="" />

        </div>
      </div>
    </div>
  )
}

export default Herosection