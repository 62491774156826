import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Whatsapp from './Components/Whatsapp_btn/Whatsapp';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import PreLoader from './Components/PreLoader/PreLoader';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {

      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 2400));

      if (document.readyState !== 'loading') {
        setLoading(false);
      }
    };

    fetchData();
  })
  return (
    <div className="App">
      <BrowserRouter>
      <Whatsapp/>
      <PreLoader loading={loading} />

      <ScrollToTop/>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />

        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
