import React from 'react'
import '../../styles/Introsection.css'

function Introsection() {
  return (
    <div className='Introsection'>
      <div className="wave-layer"></div>
      <div className="container">
        <div className="row">
          <div className="left-col custom-fade-right"data-aos="custom-fade-right" data-aos-duration="1000">
            <div className="img-container">
              <img src="img/intro-section-cone.jpg" alt="" />
            </div>
          </div>
          <div className="right-col custom-fade-left"data-aos="custom-fade-left" data-aos-duration="1000">
            <h2>Welcome to Chiccone, your one-stop shop for chicken cone adventures!</h2>
            <p>At Chiccone, we're on a mission to redefine the way you enjoy chicken. We put our heart and soul into crafting every cone, using a special blend of secret spices and perfected cooking techniques. The result? Golden, crispy cones bursting with unique flavor that you won't find anywhere else. Join us on a journey of flavor innovation and discover why our chicken cones are a must-try sensation. Come experience the magic for yourself – your taste buds will thank you!</p>
            <div className="button">
              <a href="about">Read More</a>

            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default Introsection