import React from 'react'
import '../../styles/AboutUs.css'

function AboutUs() {
    return (
        <div className='AboutUs'>
            <div className="wave-layer"></div>
            <div className="container">
                <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000" data-aos-offset="150">
                    <div className="img-container">
                        <img src="img/about-pic.jpg" alt="about" />
                    </div>
                </div>
                <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                    <h2>Chiccone :  Where Every Bite is an Adventure.</h2>
                    <p>Forget just another meal. We pioneer on culinary journeys, fueled by a passion for poultry and the art of the chicken cone. Our dedicated team, driven by flavor artistry, handcrafts the crispiest, most delicious cones imaginable. We transform simple ingredients into an explosion of taste, delivering memorable experiences from our kitchen to your plate.
                        <br></br>
                        <br></br>
                        Quality, innovation, and the pure joy of savor guide our mission. Each bite is a celebration of crunch, a testament to culinary excellence. Join us on our delicious adventure, one delightful cone at a time.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default AboutUs