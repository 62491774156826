import React, { useState } from 'react';
import '../../styles/Map.css';

function Map() {
  const [showFirstMap, setShowFirstMap] = useState(true);

  const toggleMap = () => {
    setShowFirstMap(!showFirstMap);
  };

  return (
    <div className='Map'>
      <div className="container">
        {showFirstMap ? (
          <iframe className='darkmode_map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14310.7182946753!2d50.6075153!3d26.272061!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49a799f3bf399b%3A0xc01f8a0c68d81995!2sChiccone%20Bahrain!5e0!3m2!1sen!2sin!4v1707752542491!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        ) : (
          <iframe className='darkmode_map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15715.386117867805!2d76.3172653!3d10.0295205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d20196d5c1b%3A0xccacd42b6c2657e1!2sChiccone!5e0!3m2!1sen!2sin!4v1709721851005!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        )}
        <button onClick={toggleMap}>Switch Location to {showFirstMap?'Kochi':'Bahrain'}</button>
      </div>
    </div>
  );
}

export default Map;
