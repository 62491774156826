import React from 'react'
import '../../styles/Whychoose.css'

function Whychoose() {
    return (
        <div className='Whychoose'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Why Choose Us</h2>
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h3>Uncompromising Quality, Unforgettable Taste!</h3>
                        <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-offset="100">
                        <img src="img/flavor.png" alt="flavor" />

                            <p><span>Quality Ingredients, Unmatched Flavors:</span>At Chiccone, we prioritize quality above all else. Our commitment to using only the freshest, finest ingredients ensures that every bite of our chicken cones is bursting with flavor and goodness. From succulent chicken to hand-selected herbs and spices, taste the difference that quality makes with Chiccone.</p>

                        </div>
                        <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-offset="100">
                            <img src="img/taste.png" alt="quality" />
                            <p><span>Unique Products, Unforgettable Taste:</span> At Chiccone, we pride ourselves on offering more than just chicken cones – we offer an experience like no other. Our innovative approach to flavor and presentation sets us apart from the rest, ensuring that each bite is a journey of culinary delight. From our one-of-a-kind signature creations to our seasonal specials, every Chiccone product is crafted with creativity and care to deliver an unforgettable taste sensation.</p>

                        </div>
                        <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-offset="100">
                            <img src="img/trust.png" alt="flavor" />
                            <p><span>Trusted by Satisfied Customers: </span> Our loyal customers are the heart and soul of Chiccone. With glowing reviews and testimonials, we're proud to have earned the trust and satisfaction of our community. Join the Chiccone family today and taste for yourself why our chicken cones are the talk of the town.</p>

                        </div>
                        <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-offset="100">
                            <img src="img/customer-satisfaction.png" alt="flavor" />
                            <p><span>Your Satisfaction, Our Guarantee:</span> At Chiccone, we're committed to delivering excellence in every bite. From rigorous quality control measures to attentive customer service, your satisfaction is our top priority. Rest assured that when you choose Chiccone, you're choosing nothing but the best.</p>

                        </div>
                        

                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <div className="img-container">
                            <img src="img/whychooseusCone.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whychoose