import React, { useEffect, useState } from 'react'
import '../../styles/FeaturedProducts.css';

// Sample array of products
const products = [
    {
        name: 'Spanish Chicken Cone',
        category: 'Cone',
        price: '1.400 BD',
        imageUrl: 'img/products/spanish-cone-min.jpg',
    },
    {
        name: 'Dynamite Chicken Cone',
        category: 'Cone',
        price: '1.400 BD',
        imageUrl: 'img/products/dynamite-min.jpg',
    },
    {
        name: 'Tex Mex Chicken Cone',
        category: 'Cone',
        price: '1.400 BD',
        imageUrl: 'img/products/TEx-min.jpg',
    },
    {
        name: 'Korean Chicken Cone',
        category: 'Cone',
        price: '1.100 BD',
        imageUrl: 'img/products/korean-min.jpg',
    },
    {
        name: 'Smoke BBQ Chicken Cone',
        category: 'Cone',
        price: '1.100 BD',
        imageUrl: 'img/products/smoke-min.jpg',
    },
    {
        name: 'Cheesy Chicken Cone',
        category: 'Cone',
        price: '1.800 BD',
        imageUrl: 'img/products/cheesy-min.jpg',
    },
    {
        name: 'Jalapeno Cheesy Cone',
        category: 'Cone',
        price: '1.800 BD',
        imageUrl: 'img/products/jalepano-min.jpg',
    },
    
   
    {
        name: '6 Pcs Strips Combo',
        category: 'Strips Combo',
        price: '2.000 BD',
        imageUrl: 'img/products/stripscombo-min.jpg',
    },
    {
        name: '12 Pcs Strips Combo',
        category: 'Strips Combo',
        price: '4.000 BD',
        imageUrl: 'img/products/stripscombo-min.jpg',
    },
    // Add more products here
];
function FeaturedProducts() {
    const [selectedCategory, setSelectedCategory] = useState('All');

    // Filter products based on selected category
    const filteredProducts = selectedCategory === 'All'
        ? products
        : products.filter(product => product.category === selectedCategory);


        useEffect(()=>{
            const cards = document.querySelectorAll('.card');
            cards.forEach(card =>{
                card.style.animation = 'zoom-out 400ms';
            })
        },[selectedCategory])
    return (
        <div className='FeaturedProducts'>
            <h2 data-aos="fade" data-aos-duration="1000">Featured Products</h2>

            <div className="container">
                <div className="category-buttons" data-aos="fade" data-aos-duration="1000" >
                    <button onClick={() => setSelectedCategory('All')} className={selectedCategory === 'All' ? 'active' : 'not-active'}>All</button>
                    <button onClick={() => setSelectedCategory('Cone')} className={selectedCategory === 'Cone' ? 'active' : 'not-active'}>Cones</button>
                    <button onClick={() => setSelectedCategory('Strips Combo')} className={selectedCategory === 'Strips Combo' ? 'active' : 'not-active'}>Strips Combo</button>
                    {/* <button onClick={() => setSelectedCategory('Dips')} className={selectedCategory === 'Dips' ? 'active' : 'not-active'}>Dips</button>
                    <button onClick={() => setSelectedCategory('Appetizers')} className={selectedCategory === 'Appetizers' ? 'active' : 'not-active'}>Appetizers</button> */}
                </div>
                <div className="card-container">
                    {filteredProducts.map((product, index) => (
                        <div className="card" data-aos="fade" data-aos-duration="1000" key={index}>
                            <div className="img-container">
                                <img src={product.imageUrl} alt={product.name} />

                            </div>
                            <div className="content">
                                <h3>{product.name}</h3>
                                {/* <p>{product.price}</p> */}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className="button">
                    <a href="files/chiccone_products.pdf">View Products Brochure</a>
                </div> */}

            </div>
        </div>
    )
}

export default FeaturedProducts