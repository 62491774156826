import React from 'react'
import ContactBanner from '../Components/ContactBanner/ContactBanner'
import Footer from '../Components/Footer/Footer'
import GetInTouch from '../Components/GetInTouch/GetInTouch'
import Map from '../Components/Map/Map'

function Contact() {
  return (
    <div className='Contact'>
    <ContactBanner/>
    <GetInTouch/>
    <Map/>
    <Footer/>

    </div>
  )
}

export default Contact