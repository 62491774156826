import React, { useContext } from 'react'
import '../../styles/Navbar.css'
import MenuContext from '../../Context/menuProvider'
import { Link, useLocation } from 'react-router-dom';





function Navbar() {
    const { menuOpen, handleToggleMenu, navbar, handleNavbar, setImage, overlay, image, setOverlay } = useContext(MenuContext);
    const location = useLocation();

    const handleMenuItemClick = () => {
        if (window.innerWidth < 576) {
            handleToggleMenu();
        }
    };
    const shouldReload = (path) => {
        // Check if the clicked link matches the current location
        return location.pathname === path;
    };

    return (
        <div>
            <div className='Navbar' data-aos="fade" data-aos-duration="1000">
                <header className={navbar ? 'header active' : 'header'}>
                    <div className="logo">
                        <Link to="/"onClick={() => {  setImage("img/Chiccone_Logo_circle.png")}}><img src={image} alt="chiccone logo" /></Link>
                    </div>
                    <nav className='nav-menu'>
                        <ul className={menuOpen ? 'nav-links active' : 'nav-links'}>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/') && window.location.reload(); setImage("img/Chiccone_Logo_circle.png")}} to="/">Home</Link>
                            </li>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/about') && window.location.reload(); }} to="/about">About</Link>
                            </li>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/contact') && window.location.reload(); }} to="/contact">Contact</Link>
                            </li>
                        </ul>
                        <div className="menu-button">
                            <label htmlFor="check">

                                <input type="checkbox" id="check" onClick={handleToggleMenu} />
                                <span></span>
                                <span></span>
                                <span></span>
                            </label>
                        </div>
                        <div className={overlay ? 'overlay active' : 'overlay'}>

                        </div>
                    </nav>

                </header>
            </div>
        </div>

    )
}

export default Navbar