import React from 'react'
import Herosection from '../Components/Herosection/Herosection'
import Introsection from '../Components/Introsection/Introsection'
import Whychoose from '../Components/WhyChooseus/Whychoose'
import FeaturedProducts from '../Components/FeaturedProducts/FeaturedProducts'
import Footer from '../Components/Footer/Footer'
import Testimonials from '../Components/Testimonials/Testimonials'
import Navbar from '../Components/Navbar/Navbar'

function Home() {
  return (
    <div className='Home'>
      <Navbar />
      <Herosection />
      <Introsection />
      <Whychoose />
      <FeaturedProducts />
      <Testimonials />
      <Footer />
    </div>
  )
}

export default Home